import React from "react";

export default function TwoTrucks() {
  return (
    <svg
      fill="none"
      width="524"
      height="557"
      viewBox="0 0 524 557"
      strokeWidth=".6"
      fillRule="evenodd"
      xmlns="http://www.w3.org/2000/svg">
      <g className="svg-truck-2" stroke="#fff" fill="#001224">
        <path d="M151.29 345.79v-3.84m-65.31 46.7l43.82-25.3m-49.05 16.76v4.85" stroke="#fff" />
        <path d="M146.83 347.74c-5.18 3.01-9.42 10.3-9.42 16.32 0 6.01 4.24 8.44 9.42 5.43 5.24-2.99 9.45-10.3 9.45-16.32 0-6-4.2-8.45-9.45-5.43z" />
        <path d="M144.78 342.04c-1.68-.91-3.98-.74-6.53.7-5.22 3.02-9.43 10.35-9.43 16.36 0 2.88.98 4.95 2.57 5.98l8.58 4.96c-1.58-1.04-2.56-3.1-2.56-5.98 0-6.01 4.24-13.3 9.42-16.32 2.61-1.5 4.93-1.64 6.62-.7l-8.65-5h-.02z" />
        <path
          d="M146.83 350.89c-3.67 2.14-6.66 7.33-6.66 11.58 0 4.27 2.99 6 6.66 3.88 3.72-2.14 6.71-7.31 6.71-11.6 0-4.26-3-5.98-6.7-3.86z"
          fill="none"
        />
        <path
          d="M146.83 356.22a4.6 4.6 0 00-2.05 3.59c0 1.33.92 1.86 2.05 1.2a4.57 4.57 0 002.08-3.6c0-1.28-.93-1.83-2.08-1.19z"
          fill="none"
        />
        <path d="M290.5 264.78c-5.18 3.02-9.42 10.31-9.42 16.33 0 6.01 4.24 8.44 9.43 5.43 5.24-2.99 9.44-10.32 9.44-16.32 0-6.01-4.2-8.46-9.44-5.44z" />
        <path d="M288.46 259.08c-1.68-.9-3.98-.74-6.53.71-5.22 3.02-9.44 10.34-9.44 16.35 0 2.88.98 4.95 2.57 5.98l8.58 4.96c-1.57-1.03-2.56-3.1-2.56-5.97 0-6.02 4.24-13.31 9.43-16.33 2.6-1.49 4.92-1.64 6.62-.7l-8.65-5h-.02z" />
        <path
          d="M290.5 267.94c-3.67 2.14-6.66 7.32-6.66 11.58 0 4.27 2.99 5.98 6.67 3.87 3.7-2.12 6.7-7.32 6.7-11.59s-3-5.97-6.7-3.86z"
          fill="none"
        />
        <path
          d="M290.5 273.27a4.6 4.6 0 00-2.04 3.59c0 1.32.92 1.86 2.05 1.19a4.57 4.57 0 002.07-3.59c0-1.29-.93-1.83-2.07-1.2z"
          fill="none"
        />
        <path d="M267.68 277.96c-5.2 3.02-9.43 10.32-9.43 16.33 0 6.01 4.24 8.44 9.43 5.43 5.23-2.99 9.44-10.31 9.44-16.31 0-6.02-4.2-8.46-9.44-5.45z" />
        <path d="M265.63 272.26c-1.69-.9-4-.73-6.53.72-5.22 3.01-9.44 10.34-9.44 16.35 0 2.88.98 4.94 2.57 5.98l8.57 4.95c-1.57-1.03-2.55-3.1-2.55-5.97 0-6 4.24-13.3 9.43-16.33 2.6-1.48 4.92-1.63 6.61-.69l-8.65-5h0z" />
        <path
          d="M267.68 281.13c-3.69 2.13-6.67 7.32-6.67 11.58s2.99 5.98 6.67 3.87c3.7-2.13 6.7-7.33 6.7-11.6s-3-5.98-6.7-3.85z"
          fill="none"
        />
        <path
          d="M267.68 286.45a4.59 4.59 0 00-2.05 3.6c0 1.32.91 1.85 2.05 1.18a4.54 4.54 0 002.07-3.58c0-1.3-.93-1.85-2.07-1.2z"
          fill="none"
        />
        <path d="M244.58 291.3c-5.18 3.03-9.42 10.32-9.42 16.33 0 6.02 4.24 8.45 9.42 5.44 5.24-3 9.45-10.31 9.45-16.31 0-6.02-4.2-8.47-9.45-5.45z" />
        <path d="M242.54 285.62c-1.69-.92-4-.75-6.54.7-5.2 3.02-9.44 10.34-9.44 16.35 0 2.89.99 4.95 2.57 5.98l8.58 4.96c-1.57-1.04-2.55-3.1-2.55-5.98 0-6 4.24-13.3 9.42-16.32 2.6-1.49 4.92-1.63 6.62-.7l-8.65-5h0z" />
        <path
          d="M244.58 294.47c-3.68 2.14-6.67 7.32-6.67 11.58s3 5.99 6.67 3.87c3.71-2.13 6.7-7.32 6.7-11.6 0-4.25-2.99-5.97-6.7-3.85z"
          fill="none"
        />
        <path
          d="M244.58 299.8a4.6 4.6 0 00-2.04 3.58c0 1.34.92 1.86 2.04 1.2a4.54 4.54 0 002.08-3.58c0-1.3-.93-1.85-2.08-1.2z"
          fill="none"
        />
        <path d="M77.64 387.68c-5.18 3.02-9.42 10.31-9.42 16.33 0 6.02 4.24 8.44 9.42 5.43 5.24-2.99 9.45-10.3 9.45-16.31 0-6-4.2-8.46-9.45-5.45z" />
        <path d="M75.6 381.99c-1.69-.91-4-.75-6.54.7-5.22 3.03-9.43 10.34-9.43 16.36 0 2.88.98 4.94 2.57 5.99l8.57 4.95c-1.57-1.04-2.55-3.1-2.55-5.98 0-6.02 4.24-13.3 9.42-16.33 2.6-1.47 4.92-1.63 6.62-.7l-8.65-5h-.01z" />
        <path
          d="M77.64 390.84c-3.68 2.15-6.67 7.34-6.67 11.58 0 4.27 3 6 6.67 3.87 3.71-2.12 6.7-7.31 6.7-11.59 0-4.27-2.99-5.98-6.7-3.86z"
          fill="none"
        />
        <path
          d="M77.64 396.17a4.62 4.62 0 00-2.04 3.6c0 1.31.91 1.85 2.04 1.18a4.55 4.55 0 002.08-3.58c0-1.3-.94-1.84-2.08-1.2z"
          fill="none"
        />
        <path d="M98.04 361.34l6.35 3.68v-68.87L53 266.47v28.46" stroke="none" />
        <path d="M98.04 361.34l6.35 3.68v-68.87L53 266.47v28.46" fill="none" stroke="#288CFF" />
        <path d="M53 266.47l51.4 29.68 224.44-129.6-51.4-29.67L53 266.47z" stroke="#288CFF" />
        <path d="M98.1 365.4l6.3 3.63v-4.01l-6.36-3.68" stroke="none" />
        <path d="M98.1 365.4l6.3 3.63v-4.01l-6.36-3.68" fill="none" stroke="#288CFF" />
        <path
          d="M104.4 365.01l224.44-129.6v-68.86L104.4 296.15v68.87zm0 4.02l224.44-129.6v-4.01L104.4 365.02v4.01z"
          stroke="#288CFF"
        />
        <path
          d="M51.7 399.46L.3 369.79v11.9a9.7 9.7 0 004.86 8.4l42.13 24.32a9.7 9.7 0 009 .37l8.15-3.86v-8.9c0-1.72 2.73-12.78 7.67-16.92l10.46-6.04c2.3-1.22 3.06.17 3.38 1.34l12.2-7.03v-5.78l-26.6 15.36-5.73 8.35-14.12 8.16z"
          stroke="#fff"
        />
        <path
          d="M15.5 390.95l21.2 12.25 1.7-9.36-24.63-14.39 1.74 11.5z"
          fill="none"
          strokeWidth=".5"
        />
        <path
          d="M39.78 399.1l10.6 6.1v-4.51l-10.6-6.13v4.53zM1.55 377.01l10.6 6.12v-4.51l-10.6-6.12v4.5z"
          fill="none"
        />
        <path d="M3.85 333.14l-1.06 15.2 51.39 29.67 1.16-16.73-50.5-28.93-.99.79z" />
        <path d="M3.6 333.14a30.9 30.9 0 0115.46-26.76l26.89-15.52 51.39 29.67-27.5 15.88a31.87 31.87 0 00-15.88 25.66L3.61 333.14zm-2.05 16.15L.3 369.79l51.4 29.67 1.24-20.5-51.4-29.67z" />
        <path d="M53.96 362.07L83.7 344.9c.8-.47 1.81.11 1.82 1.04l.01 3.45a9.71 9.71 0 01-3.84 7.78l-28.75 21.79-1.24 20.5 14.12-8.16 5.72-8.35 26.6-15.36-.8-47.06-27.5 15.88a31.87 31.87 0 00-15.88 25.66z" />
        <path d="M1.55 349.29l51.39 29.67 1.24-.95-51.4-29.67-1.23.95zM54.18 378l27.5-20.83a9.71 9.71 0 003.85-7.78l-.01-3.45c0-.93-1.02-1.51-1.82-1.04l-28.36 16.38L54.18 378z" />
        <path
          d="M49.95 383.97L3.48 357.14m46.28 30.42L3.29 360.73m46.28 30.41L3.1 364.32"
          fill="none"
          strokeWidth=".5"
        />
      </g>
      <g className="svg-truck-1">
        <path d="M345.1 485.66v-3.83m-65.3 46.69l43.81-25.3m-49.05 16.76v4.85" stroke="#fff" />
        <path
          d="M340.65 487.61c-5.19 3.02-9.42 10.31-9.42 16.32 0 6.02 4.23 8.45 9.42 5.43 5.24-2.98 9.44-10.3 9.44-16.32 0-6-4.2-8.45-9.44-5.43z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M338.6 481.92c-1.68-.92-3.98-.75-6.53.7-5.22 3.02-9.44 10.34-9.44 16.35 0 2.89.98 4.95 2.57 5.98l8.58 4.97c-1.57-1.04-2.55-3.11-2.55-5.99 0-6.01 4.23-13.3 9.42-16.32 2.6-1.49 4.92-1.63 6.62-.7l-8.65-5h-.02z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M340.65 490.76c-3.68 2.15-6.67 7.34-6.67 11.58 0 4.28 3 6 6.67 3.88 3.71-2.14 6.7-7.31 6.7-11.6 0-4.26-2.99-5.97-6.7-3.86z"
          stroke="#fff"
        />
        <path
          d="M340.65 496.1a4.6 4.6 0 00-2.05 3.58c0 1.33.91 1.87 2.05 1.2a4.57 4.57 0 002.08-3.59c0-1.29-.94-1.84-2.08-1.2z"
          stroke="#fff"
        />
        <path
          d="M484.32 404.66c-5.18 3.01-9.42 10.3-9.42 16.32s4.24 8.44 9.42 5.44c5.24-3 9.45-10.32 9.45-16.32 0-6.02-4.21-8.46-9.45-5.44z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M482.28 398.95c-1.69-.9-3.99-.74-6.54.72-5.21 3.01-9.43 10.33-9.43 16.35 0 2.88.98 4.94 2.57 5.98l8.57 4.95c-1.56-1.03-2.55-3.1-2.55-5.97 0-6.02 4.24-13.3 9.42-16.32 2.6-1.5 4.93-1.64 6.62-.7l-8.65-5h-.01z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M484.32 407.81c-3.68 2.14-6.67 7.33-6.67 11.59s3 5.98 6.67 3.87c3.71-2.13 6.7-7.33 6.7-11.6s-2.99-5.97-6.7-3.86z"
          stroke="#fff"
        />
        <path
          d="M484.32 413.14a4.6 4.6 0 00-2.04 3.6c0 1.32.91 1.85 2.04 1.18a4.57 4.57 0 002.08-3.58c0-1.3-.93-1.84-2.08-1.2z"
          stroke="#fff"
        />
        <path
          d="M461.49 417.83c-5.19 3.03-9.42 10.33-9.42 16.34s4.23 8.44 9.42 5.42c5.24-2.98 9.45-10.3 9.45-16.3 0-6.02-4.21-8.46-9.45-5.46z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M459.45 412.14c-1.7-.9-4-.74-6.54.7-5.21 3.03-9.43 10.35-9.43 16.36 0 2.89.97 4.94 2.56 5.99l8.58 4.95c-1.57-1.03-2.55-3.1-2.55-5.98 0-6 4.23-13.3 9.42-16.33 2.6-1.48 4.92-1.63 6.62-.69l-8.66-5h0z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M461.49 421c-3.68 2.13-6.67 7.32-6.67 11.58s2.99 5.99 6.67 3.87c3.71-2.13 6.7-7.32 6.7-11.6 0-4.27-2.99-5.97-6.7-3.85z"
          stroke="#fff"
        />
        <path
          d="M461.49 426.33a4.59 4.59 0 00-2.04 3.59c0 1.32.9 1.85 2.04 1.18a4.54 4.54 0 002.08-3.57c0-1.3-.94-1.85-2.08-1.2z"
          stroke="#fff"
        />
        <path
          d="M438.4 431.18c-5.19 3.03-9.43 10.32-9.43 16.32 0 6.03 4.24 8.46 9.43 5.44 5.24-3 9.44-10.3 9.44-16.31 0-6.02-4.2-8.47-9.44-5.45z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M436.36 425.5c-1.7-.92-4-.76-6.54.7-5.21 3.01-9.44 10.33-9.44 16.34 0 2.89.98 4.95 2.57 5.98l8.58 4.97c-1.57-1.04-2.56-3.11-2.56-5.99 0-6 4.24-13.3 9.43-16.32 2.6-1.48 4.92-1.63 6.61-.7l-8.64-4.99h-.01z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M438.4 434.34c-3.68 2.15-6.67 7.33-6.67 11.58 0 4.27 2.99 5.99 6.67 3.87 3.7-2.13 6.7-7.32 6.7-11.59 0-4.26-3-5.97-6.7-3.86z"
          stroke="#fff"
        />
        <path
          d="M438.4 439.67a4.6 4.6 0 00-2.04 3.59c0 1.33.91 1.86 2.04 1.19a4.54 4.54 0 002.08-3.58c0-1.3-.94-1.85-2.08-1.2z"
          stroke="#fff"
        />
        <path
          d="M271.46 527.56c-5.19 3.02-9.42 10.3-9.42 16.32s4.23 8.45 9.42 5.44c5.24-3 9.44-10.31 9.44-16.32 0-6-4.2-8.46-9.44-5.44z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M269.41 521.86c-1.69-.9-3.99-.74-6.53.7-5.22 3.03-9.44 10.34-9.44 16.37 0 2.88.98 4.93 2.57 5.98l8.58 4.95c-1.57-1.03-2.55-3.1-2.55-5.98 0-6.01 4.23-13.3 9.42-16.32 2.6-1.48 4.92-1.63 6.61-.7l-8.65-5h-.01z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M271.46 530.72c-3.68 2.14-6.67 7.33-6.67 11.58 0 4.27 2.99 6 6.67 3.87 3.7-2.13 6.7-7.32 6.7-11.6 0-4.26-3-5.98-6.7-3.85z"
          stroke="#fff"
        />
        <path
          d="M271.46 536.04a4.62 4.62 0 00-2.05 3.6c0 1.32.92 1.85 2.05 1.18a4.56 4.56 0 002.08-3.58c0-1.3-.94-1.84-2.08-1.2z"
          stroke="#fff"
        />
        <path d="M291.85 501.22l6.36 3.67v-68.87l-51.4-29.68v28.46" fill="#001224" />
        <path d="M291.85 501.22l6.36 3.67v-68.87l-51.4-29.68v28.46" stroke="#288CFF" />
        <path
          d="M246.82 406.34l51.39 29.68 224.45-129.6-51.4-29.67-224.44 129.6z"
          fill="#001224"
          stroke="#288CFF"
        />
        <path d="M291.92 505.27l6.29 3.63v-4.01l-6.36-3.67" fill="#001224" />
        <path d="M291.92 505.27l6.29 3.63v-4.01l-6.36-3.67" stroke="#288CFF" />
        <path
          d="M298.2 504.89l224.46-129.6v-68.87L298.2 436.02v68.87zm0 4.01l224.46-129.59v-4.02L298.2 504.9v4.01z"
          fill="#001224"
          stroke="#288CFF"
        />
        <path
          d="M245.51 539.34l-51.4-29.68v11.9a9.7 9.7 0 004.86 8.4l42.13 24.33a9.7 9.7 0 009.01.36l8.14-3.86v-8.9c0-1.72 2.73-12.78 7.68-16.92l10.45-6.04c2.3-1.22 3.06.18 3.39 1.35l12.19-7.03v-5.79l-26.6 15.37-5.72 8.35-14.13 8.16z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M209.32 530.83l21.2 12.25 1.7-9.36-24.63-14.4 1.73 11.5z"
          stroke="#fff"
          strokeWidth=".5"
        />
        <path
          d="M233.6 538.97l10.6 6.11v-4.52l-10.6-6.12v4.53zm-38.24-22.08l10.6 6.11v-4.5l-10.6-6.13v4.52z"
          stroke="#fff"
        />
        <path
          d="M197.67 473.01l-1.07 15.2 51.4 29.67 1.16-16.73-50.5-28.93-1 .8z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M197.42 473.01a30.9 30.9 0 0115.45-26.76l26.9-15.52 51.38 29.67-27.5 15.88a31.87 31.87 0 00-15.87 25.67L197.42 473zm-2.06 16.15l-1.24 20.5 51.4 29.67 1.23-20.5-51.39-29.67z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M247.78 501.95l29.73-17.18c.81-.46 1.82.11 1.82 1.05l.02 3.44a9.71 9.71 0 01-3.85 7.79l-28.75 21.78-1.24 20.5 14.13-8.15 5.72-8.35 26.6-15.37-.8-47.06-27.5 15.88a31.87 31.87 0 00-15.89 25.67z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M195.36 489.16l51.4 29.67 1.23-.95-51.39-29.66-1.24.94zM248 517.88l27.5-20.83a9.71 9.71 0 003.85-7.79l-.02-3.44c0-.94-1.01-1.51-1.82-1.05l-28.35 16.38-1.16 16.73z"
          fill="#001224"
          stroke="#fff"
        />
        <path
          d="M243.77 523.84l-46.47-26.83m46.27 30.42L197.1 500.6m46.29 30.42l-46.48-26.83"
          stroke="#fff"
          strokeWidth=".5"
        />
      </g>
      <path
        className="svg-truck-cache"
        d="M0 557h524V0H0v557z"
        fill="url(#svg-truck-cache-gradient)"
      />
      <path
        d="M164.91 58.95l-1 80"
        stroke="#288CFF"
        strokeDasharray="2 6"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M164.84 58.95a28.97 28.97 0 100-57.95 28.97 28.97 0 000 57.95z"
        fill="#001224"
        stroke="#288CFF"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M173.83 27.97c0 7-8.99 13-8.99 13s-8.99-6-8.99-13a9 9 0 0117.98 0z"
        fill="#001224"
        stroke="#288CFF"
        strokeWidth="1.5"
      />
      <path
        d="M166.96 25.86a3 3 0 11-4.24 4.23 3 3 0 014.24-4.23z"
        fill="#001224"
        stroke="#fff"
        strokeWidth="1.5"
      />
      <path
        d="M387.63 196.82a28.97 28.97 0 100-57.94 28.97 28.97 0 000 57.94z"
        fill="#001224"
        stroke="#288CFF"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path d="M382.66 164.43l9.85-5.25" stroke="#fff" strokeWidth="1.5" />
      <path
        d="M377.96 162.01l9.67 4.84 9.67-4.84m-9.67 16.59v-11.75"
        stroke="#288CFF"
        strokeWidth="1.5"
      />
      <path
        d="M388.52 157.3l8 4 1.1.58v11.94l-1.1.58-8 4-.89.44-.9-.44-8-4-1.09-.58v-11.94l1.1-.57 8-4 .89-.45.89.45z"
        stroke="#288CFF"
        strokeWidth="1.5"
      />
      <path
        d="M388.7 196.82l-1 80"
        stroke="#288CFF"
        strokeDasharray="2 6"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="svg-truck-cache-gradient"
          gradientUnits="userSpaceOnUse"
          x1="335.5"
          x2="167.01"
          y1="182"
          y2="366.36">
          <stop stopColor="#001224" />
          <stop offset=".51" stopColor="#001224" stopOpacity=".67" />
          <stop offset="1" stopColor="#001224" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
